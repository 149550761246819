import React from 'react';
import hundredK from '../../../Images/100knews.webp';
import duBus from '../../../Images/duBus.jpg';
import duLIO from '../../../Images/duLIO.jpg';
import river100k from '../../../Images/river100k.jpg';

const News = () => {
    return (
        <div>
             <div className='my-10 border-dotted border-r-2 border-r-indigo-600 '>
            <div className=' grid grid-cols-1 gap-12 md:grid-cols-4  lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 my-16'>
                <div className='col-span-0 my-5'>
                    <p className=' text-2xl lg:pr-3 '>News</p>
                </div>

                <div className='col-span-3 md: lg:pl-3   xl: xxl: '>
                <ul class="list-none">
                    <li className='my-5 grid grid-cols-4'>
                        <img src={hundredK} className=" newsPic pr-3 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p> <a target='_blank' className=' text-lg ' href="https://thedailycampus.com/debate/72497/%E0%A6%8F%E0%A6%95-%E0%A6%AC%E0%A6%95%E0%A7%8D%E0%A6%A4%E0%A7%83%E0%A6%A4%E0%A6%BE%E0%A7%9F-%E0%A6%B2%E0%A6%BE%E0%A6%96%E0%A6%AA%E0%A6%A4%E0%A6%BF-%E0%A6%A2%E0%A6%BE%E0%A6%AC%E0%A6%BF%E0%A6%B0-?fbclid=IwAR2UQxgIcAV-lZoblQ6Vx_HHzHIbKckn5XbiX_MXdmqpcPQLE1tqlJY3rz0"> এক বক্তৃতায় লাখপতি ঢাবির তিন শিক্ষার্থী </a></p>
                        </div>
                    </li>
                    <li className='my-5 grid grid-cols-4'>
                        <img src={duBus} className=" newsPic pr-3 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p> <a target='_blank' className=' text-lg ' href="https://www.banglanews24.com/education/news/bd/941882.details?fbclid=IwAR1tkuo7f8kJwVmfic6wb8ABiw3uR6QpCtAFv9SPg85FN8z_j_mNX_NlDN8"> ঢাবি-শরীয়তপুর রুটে বাস চালুর দাবিতে স্মারকলিপি </a></p>
                        </div>
                    </li>

                    <li className='my-5 grid grid-cols-4'>
                        <img src={duLIO} className=" newsPic pr-3 col-span-1"  alt="" />
                        <div className='col-span-3'>
                       
                        <p> <a target='_blank' className=' text-lg ' href="https://www.prothomalo.com/bangladesh/%E0%A6%A2%E0%A6%BE%E0%A6%AC%E0%A6%BF-%E0%A6%B2%E0%A6%BF%E0%A6%93-%E0%A6%95%E0%A7%8D%E0%A6%B2%E0%A6%BE%E0%A6%AC%E0%A7%87%E0%A6%B0-%E0%A6%A8%E0%A6%A4%E0%A7%81%E0%A6%A8-%E0%A6%95%E0%A6%AE%E0%A6%BF%E0%A6%9F%E0%A6%BF?fbclid=IwAR1TvcZf3iHWuct2vK5b-nZabqSCnTYvbljqAKHmPcc9poDGySaPUxQjWxA">	ঢাবি লিও ক্লাবের নতুন কমিটি। </a></p>
                        </div>
                    </li>

                    <li className='my-5 grid grid-cols-4'>
                        <img src={river100k} className=" newsPic pr-3 col-span-1"  alt="" />
                        <div className='col-span-3'>
                       
                        <p> <a target='_blank' className=' text-lg pr-5 ' href="https://campustimes.press/article/public-university/21658?fb_comment_id=4088137144543923_4448636985160602">	নদী বিষয়ক বক্তৃতায় চ্যাম্পিয়ন সাইফুল, রানার আপ মাহফুজা </a></p>
                        </div>
                    </li>

                    <li className='my-5 grid grid-cols-4'>
                        <img src="https://www.bangla.24livenewspaper.com/images/bangladesh/a-rohinga-road-to-refuge.jpg" className=" newsPic pl-3 col-span-1"  alt="" />
                        <div className='col-span-3'>
                       
                        <p> <a target='_blank' className=' text-lg ' href="https://www.bangla.24livenewspaper.com/post-17927"> হার না মানা এক রোহিঙ্গা যুবকের গল্প </a></p>
                        </div>
                    </li>

                    <li className='my-5 grid grid-cols-4'>
                        <img src="" className=" newsPic pl-3 col-span-1"  alt="image" />
                        <div className='col-span-3'>
                       
                        <p> <a target='_blank' className=' text-lg ' href="https://www.jugantor.com/todays-paper/features/protimoncho/148685/%E0%A6%AE%E0%A6%BE%E0%A6%A5%E0%A6%BE%E0%A6%B0-%E0%A6%9A%E0%A7%81%E0%A6%B2-%E0%A6%9F%E0%A7%87%E0%A6%A8%E0%A7%87-%E0%A6%A7%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B2%E0%A7%87%E0%A6%9B%E0%A6%BF-%E0%A6%95%E0%A7%87%E0%A6%A8-%E0%A6%8F%E0%A6%AE%E0%A6%A8%E0%A6%9F%E0%A6%BF-%E0%A6%B9%E0%A6%B2?fbclid=IwAR2B7vIyFQL2J3oVgZi2kTHgXP3D6ZRzMPoZ5mksU6TCR9af9WLBihuBr7U">মাথার চুল টেনে ধরে বলেছি, কেন এমনটি হল?</a></p>
                        </div>
                    </li>

                   
                </ul>
                </div>
            </div>
        </div>
        </div>
    );
};

export default News;