
import React from 'react';
import './HeroAnimention.css'
import aboutMePic from '../../../Images/AboutMe.jpg'


const HeroSrction = () => {
    return (
        <div className='mt-10'>
            <div className='flex justify-center'>
                <img src={aboutMePic} alt="" srcset="" className=' rounded-full' />
            </div>
            <div className=' grid grid-cols-1 gap-4 md:grid-cols-4  lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 mt-16'>
                <div className='col-span-0'>
                    <p className=' text-2xl '>About</p>
                </div>

                <div className='col-span-3 md:  lg:-ml-24 xl: xxl: '>
                    <p>Running Vice President of Dhaka University Debating Society (DUDS) & Former President of F Rahman Debating Club (FRDC), Former Secretary of LEO Club of Dhaka University King`s, Running Vice President of Dhaka University Literature Society (DULS) & Founding President of F Rahman Literature Society (FRLS), Former President of Kirtrinasha (A Student Welfare Association).

                        In Mujib`s centenary, I have become the best in the country for my speech titled Amar Bangabandhu and have been awarded tk 1 lakh. I have been the best speaker in multiple episodes of the 23rd National Television Debate Competition. I`m the Winner of First Prize for Creative Work in River Based Speech and got Laptop as prize. Champion on 10th Jail Killing Day Inter-Club Baroari Debate Competition 2020, 2nd Place in Victory Day HSTU DS National Baroari Debate Competition-2020 arranged by Debating Society of Hajee Mohammad Danesh Science & Technology University,
</p>
                    <p className='pt-4'>
                        1st Runner-up in CoUDS Victory Debate Festival - 2020 (Comilla University Debating Society), Champion in the Present Speech category in National Mourning Day Debate Competition 2019 organized by F Rahman Debating Club, First place in the Great Victory Day essay competition 2020 on The spirit of the liberation war and our culture organized by Dhaka University Youth Against Hunger.

                        I got Taqi Padak 2018 in the essay writing competition & Stood 1st position in Tajuddin Ahmad Memorial Essay Competition 2018 which was arranged by University of Dhaka. Also hold 1st positon in Tamaddun Majlish Essay Competition on Isa Khan Life. Get 1st prize in Environmental Article Writting Competition 2014 by Poribesh Bachao Andolon (POBA).</p>
                </div>
            </div>
        </div>

    );
};

export default HeroSrction;