import React from 'react';
import './OrganizationalActivities.css';
import duds from '../../../Images/DUDS.jpg';
import kirtinasha from '../../../Images/kirtinasha.jpg';
import frdc from '../../../Images/FRDC.jpg';
import frhlc from '../../../Images/FRHLC.png';
import bondhushava from '../../../Images/bondhushava.svg';
import provatfeeri from '../../../Images/provatfeeri.png';

const OrganizationalActivities = () => {
    return (
        <div className='my-10 border-dotted border-r-2 border-r-indigo-600 '>
            <div className=' grid grid-cols-1 gap-12 md:grid-cols-4  lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 '>
                <div className='col-span-0 my-8'>
                    <p className=' text-2xl lg:pr-3 '>Organizational <br /> Activities</p>
                </div>

                <div className='col-span-3 md: lg:pl-3   xl: xxl: '>
                <ul class="list-none">
                    <li className='my-8 grid grid-cols-4'>
                        <img src={kirtinasha} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>সাবেক সভাপতি</p>
                        <p>কীর্তিনাশা</p>
                        <p>ঢাকা বিশ্ববিদ্যালয়ে অধ্যয়নরত শরীয়তপুরে জেলার শিক্ষার্থীদের সংগঠন</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4'>
                        <img src={frdc} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>সাবেক সভাপতি</p>
                        <p>এফ রহমান ডিবেটিং ক্লাব, ঢাকা বিশ্ববিদ্যালয়।</p>
                        <p>এফ রহমান ডিবেটিং ক্লাব, ঢাকা বিশ্ববিদ্যালয়।</p>
                        </div>
                    </li>

                    <li className='my-8 grid grid-cols-4'>
                        <img src={frhlc} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>সাবেক সভাপতি</p>
                        <p>	স্যার এ এফ রহমান হল সাহিত্য সংসদ, ঢাকা বিশ্ববিদ্যালয়।</p>
                        <p>	স্যার এ এফ রহমান হল সাহিত্য সংসদ, ঢাকা বিশ্ববিদ্যালয়।</p>
                        </div>
                    </li>

                    <li className='my-8 grid grid-cols-4'>
                        <img src={duds} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>সহ-সভাপতি</p>
                        <p>ঢাকা ইউনিভার্সিটি ডিবেটিং সোসাইটি।</p>
                        <p>ঢাকা ইউনিভার্সিটি ডিবেটিং সোসাইটি।</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4'>
                        <img src={bondhushava} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>বিতর্ক ও অনুষ্ঠান সম্পাদক</p>
                        <p>প্রথম আলো - বন্ধুসভা জাতীয় কমিটি।</p>
                        <p>প্রথম আলো - বন্ধুসভা জাতীয় কমিটি।</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4'>
                        <img src={provatfeeri} className=" organizationLogo col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>সাবেক যুগ্ম সাধারণ সম্পাদক</p>
                        <p>প্রভাতফেরী।।</p>
                        <p>টিএসসি ভিত্তিক সাংস্কৃতিক সংগঠন </p>
                        </div>
                    </li>
                {/* </marquee> */}
                </ul>
                </div>
            </div>
        </div>
    );
};

export default OrganizationalActivities;