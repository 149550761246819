import React from 'react';
import './Speech.css'
const Speech = () => {
    return (
        <div>
            <h1 className="text-3xl font-bold my-32 "> Some Of <span style={{ color: 'rgb(11 194 194)' }}> My Speech </span></h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-y-4 " >
               
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/8kFd-cYP468" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/PDF2QEdQkU0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/QaKRKrvGDt0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/VdiPfaYGctk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/QxtSiqLuB8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/VZGu0a9RmqU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/0nfxOFiGYVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/bPgO5DeN5YU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe  className='rounded-xl h-fit speech' src="https://www.youtube.com/embed/yyfn8vxcmnY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    {/* <iframe className='rounded-xl h-fit speech' src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmsikhan11%2Fvideos%2F343884921283795%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
            
            </div>

           


            <div className='text-center'>
                <button className=' px-3 py-2 mt-5 rounded-md bg-cyan-500' onClick={()=> window.open('https://www.youtube.com/c/MSIKHAN','_blank')} > Visite My Youtube Channel</button>
            </div>
        </div>
    );
};

export default Speech;