import React from 'react';

const OnTelevision = () => {
    return (
        <div className='my-10'>
            <div className=' grid grid-cols-1 gap-12 md:grid-cols-4  lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 '>
                <div className='col-span-0 my-8'>
                    <p className=' text-2xl lg:pl-3 '>On Television</p>
                </div>

                <div className='col-span-3 md: lg:pl-3   xl: xxl: '>
                <ul class="list-none">
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/Gl22fRL7Z0I','_blank')}     >
                        <img src='https://i.ytimg.com/vi/Gl22fRL7Z0I/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLC3HAkiq6vGlii_hJASZElINdCB5Q' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>ধর্ষিতা শব্দটি পরিহার করুন | ATN NEWS | News Hour Xtra | MSI KHAN</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/IiIdF2NUgOA','_blank')}   >
                        <img src='https://i.ytimg.com/vi/IiIdF2NUgOA/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLDyyiudmmQBAETK-dmoBMIAE0QRmg' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>অর্ধশতকে তারুণ্যের ভাবনা by BanglaVision NEWS</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/lfhZ-opovKM','_blank')}   >
                        <img src='https://i.ytimg.com/vi/lfhZ-opovKM/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLD8W-3B37bGKi62xK2r_N0cu94cNg' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>News Hour Xtra - চিৎকার করো মেয়ে</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/puRGiQOBErE','_blank')}   >
                        <img src='https://i.ytimg.com/vi/puRGiQOBErE/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLCInVynT16u8vubKecShWS1vb795w' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>জীবনের প্রথম টিভি টকশো | গণতন্ত্রের সংলাপ | My First TV talk Show on Rtv</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/vagdKhxqaHw','_blank')}   >
                        <img src='https://i.ytimg.com/vi/vagdKhxqaHw/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBxgGyhM6JyrPXnQfUv6HZ5bQyv3Q' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>হল খোলার দাবিতে শিক্ষার্থীরা কেন মরিয়া? | Students of University Demand Opening Dorms Soon</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/uU6oWIkVlyY','_blank')}   >
                        <img src='https://i.ytimg.com/vi/uU6oWIkVlyY/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLDJx1UAHPXm5WiSBvJFlcvz9XthPA' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>জাতীয় বিতর্ক: পদ্মা সেতু আমাদের শিল্পখাতের চেয়ে কৃষিখাতকে অধিক সমৃদ্ধ করবে by Bangladesh Television</p>
                        </div>
                    </li>
                    <li className='my-8 grid grid-cols-4 cursor-pointer'  onClick={()=> window.open('https://youtu.be/GxC2CoGFpWI','_blank')}   >
                        <img src='https://i.ytimg.com/vi/GxC2CoGFpWI/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLDIScsQ3gMwH5bbjSRksROVknqaGQ' className="  organizationLogo pr-2 col-span-1"  alt="" />
                        <div className='col-span-3'>
                        <p>বিতর্ক প্রতিযোগিতা</p>
                        </div>
                    </li>

                 
                </ul>
                </div>
            </div>
        </div>
    );
};

export default OnTelevision;