import { faCodeBranch, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const GetInTouch = () => {
    const navigate = useNavigate();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_e5frntk', 'template_z59ygok', e.target, '6StT-QsowPFdYDtWF')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }


    return (
        <div className='text-center mt-16'>
            <h4 className='text-xl' style={{ color: 'rgb(11 194 194)' }}>What's Next?</h4>
            <div className="mt-8">
                <h1 className='text-5xl font-bold'>Get In Touch</h1>
                <p>I'd Love to hear from you . Whether you have a question or just want to say hi, fell free to drop a message. <br /> I'll try my best to get back to you! </p>
            </div>
            <div className=' flex flex-col items-center pt-10 '  >

                <form className='w-64 lg:w-96 md:w-80 sm:w-64 ' onSubmit={sendEmail} >
                    <div class="mb-6">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                        <input type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='Entare your Name' required name="user_name" />
                    </div>




                    <div class="mb-6">
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@flowbite.com" required name="user_email" />
                    </div>

                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                    <textarea id="message" name="message" rows="10" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>




                    <div className='text-center'>
                        <input type="submit" className=' px-3 py-2 mt-5 border-l-sky-500 rounded-md bg-cyan-500' value="Send" />
                    </div>
                </form>
            </div>



            <div className='mt-16'>
                <p>Designed & Built by Tanzid Mahamud</p>
                <p> <FontAwesomeIcon icon={faStar}></FontAwesomeIcon> Star &   <FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon>   FroksBy Tanzid Mahamud</p>
            </div>
        </div>
    );
};

export default GetInTouch;