import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Achievement1 from '../../../Images/Achievement1.jpg';
import Achievement2 from '../../../Images/barwari.jpg';
import Achievement3 from '../../../Images/achievement3.jpg';
import Achievement4 from '../../../Images/Amar-Banabandhu-Result.jpeg';
import background1 from '../../../Images/dream-bg.png';
import background2 from '../../../Images/exp-bg.png';
import './Achievements.css'
import { useNavigate } from 'react-router-dom';

const Achievements = () => {
    const navigate = useNavigate();
    const handleReadMore = () => {

    }
    return (



        <div>
            <h1 className="text-3xl font-bold my-32 "> Some Of <span style={{ color: 'rgb(11 194 194)' }}> My Achievements </span></h1>

            {/* <div className='grid grid-cols-3 gap-3 justify-items-center align-middle my-10'> */}
            <div className="articleCard my-32 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4 bg-right-top bg-no-repeat " style={{ backgroundImage: `url(${background1})` }} >
                <div> <img className='' src={Achievement1} alt="" /> </div>

                <div className='bg-[#f5f3f2]  md:-ml-12 md:flex flex-col justify-center md:px-4 md:h-[90%] md:mt-5 flex flex-col justify-center py-5 px-2 h-full -mt-5 shadow-lg '>

                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>২০২১</h2>
                    <h2 className='text-xl font-bold py-3 '>নদী বিষয়ক বক্তৃতা প্রতিযোগিতা, বাংলাদেশ রিভার ফাউন্ডেশন, প্রকৃতি ও জীবন ফাউন্ডেশন এবং রিভার এন্ড ডেল্টা রিসার্চ সেন্টারের যৌথ আয়োজন।</h2>
                    <h2 className='text-xl font-bold py-3 text-cyan-500'>প্রথম স্থান</h2>
                    <p> <small> ১৪ মার্চ আন্তর্জাতিক নদীকৃত্য দিবসে জাতীয় নদী রক্ষা কমিশনের সম্মেলন কক্ষে পুরস্কার বিতরণ অনুষ্ঠান অনুষ্ঠিত হয়। এতে প্রকৃতি ও জীবন ফাউন্ডেশনের চেয়ারম্যান মুকিত মজুমদার বাবুর সভাপতিত্বে বক্তব্য রাখেন জাতীয় নদী রক্ষা কমিশনের সার্বক্ষণিক সদস্য কামরুন নাহার আহমেদ, চট্টগ্রাম  বিশ্ববিদ্যালয়ের প্রাণিবিদ্যা বিভাগের অধ্যাপক ড. মো. মনজুরুল কিবরীয়া। প্রতিযোগিতায় প্রথম স্থান অর্জন করেন ঢাকা বিশ্ববিদ্যালয়ের মো. সাইফুল ইসলাম খান, দ্বিতীয় স্থান অর্জন করেন একই বিশ্ববিদ্যালয়ের  মাহফুজা মাহবুব নওশিন ও তৃতীয় স্থান অর্জন করেন ভিকারুননেসা নুন কলেজের কাশফিয়া কাওসার চৌধুরী।.....</small></p>
                    <div>
                        <button onClick={() => window.open('https://riverbangla.com/?p=2736', '_blank', 'noopener,noreferrer')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className="flex flex-col md:flex md:flex-row-reverse lg:flex lg:flex-row-reverse xl:flex xl:flex-row-reverse  my-32 bg-left-top bg-no-repeat" style={{ backgroundImage: `url(${background2})` }} >
                <div> <img className=' h-[500px] w-[125rem] ' src={Achievement4} alt="" /> </div>

                <div className='bg-[#f5f3f2]  md:-mr-12 md:flex flex-col justify-center md:px-4 md:h-[90%] md:mt-5 flex flex-col justify-center py-5 px-2 h-full -mt-5 md:ml-8  shadow-lg'>

                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>২০২১</h2>
                    <h2 className='text-xl font-bold py-3 '>মুজিব শতবর্ষ উপলক্ষে ‘আমার বঙ্গবন্ধু’ শীর্ষক বক্তব্য প্রতিযোগিতা, বাংলাদেশ তথ্য ও যোগাযোগ অধিদপ্তর (আইসিটি)।</h2>

                    <h2 className='text-xl font-bold py-3 text-cyan-500'>বিজয়ী</h2>
                    <p> <small>মুজিব শতবর্ষ উপলক্ষে @ICT Division আয়োজিত ‘আমার বঙ্গবন্ধু’ শীর্ষক প্রতিযোগিতায় আমার এই বক্তব্যটি বিজয়ী হয়েছে। এই ভিডিওতে স্বাধীন বাংলাদেশ রাষ্ট্রের জনক বঙ্গবন্ধু শেখ মুজিবুর রহমান সম্পর্কে আমার ভাবনা  তুলে ধরেছি।

                        মুজিব শতবর্ষ উপলক্ষে তথ্য ও প্রযুক্তি মন্ত্রণালয় ‘আমার বঙ্গবন্ধু’ বিষয়ের ওপর বক্তব্য প্রতিযোগিতার আয়োজন করে। এতে অনলাইনে সারা দেশ থেকে নানা বয়সের প্রতিযোগিরা অংশগ্রহণ করেন। প্রতিযোগিতা থেকে বাছাই পর্বে সেরা ৪০ জনকে শর্টলিস্টে রাখা হয়। পরবর্তীতে তাদের মধ্য থেকে ৫ জুলাই ১২জন বিজয়ীর নাম ঘোষণা করা হয়।.......</small></p>
                    <div>
                        <button onClick={() => window.open('https://www.amarbangabandhu.gov.bd/winner/', '_blank', 'noopener,noreferrer')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className="articleCard my-32 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4  bg-right-top bg-no-repeat" style={{ backgroundImage: `url(${background1})` }}>
                <div> <img className='' src={Achievement1} alt="" /> </div>

                <div className='bg-[#f5f3f2]   md:-ml-12 md:flex md:flex-col md:justify-center  md:px-4  md:mt-5 flex flex-col justify-center py-5 px-2 h-full md:h-[90%] -mt-5 shadow-lg  '>

                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>২০২১</h2>
                    <h2 className='text-xl font-bold py-3 '>দুর্নীতি বিরোধী প্রবন্ধ প্রতিযোগিতা, ইয়েস গ্রুপ, ট্রান্সপারেন্সি ইন্টারন্যাশনাল বাংলাদেশ।</h2>
                    <h2 className='text-xl font-bold py-3 text-cyan-500'>চ্যাম্পিয়ন</h2>
                    <p> <small> ইয়েস গ্রুপ,ট্রান্সপারেন্সি ইন্টারন্যাশনাল বাংলাদেশ কর্তৃক আয়োজিত "দুর্নীতি বিরোধী প্রবন্ধ প্রতিযোগিতা" আয়োজন করে। সেখানে অংশগ্রহণ করে " দুর্নীতি বিরোধী প্রবন্ধ প্রতিযোগিতা" চ্যাম্পিয়ন হওয়ার গৌরব অর্জন করি।</small></p>
                    <div>
                    </div>

                </div>
            </div>

            <div className="flex flex-col md:flex md:flex-row-reverse lg:flex lg:flex-row-reverse xl:flex xl:flex-row-reverse  my-32 bg-left-top bg-no-repeat" style={{ backgroundImage: `url(${background2})` }} >
                <div> <img className='' src={Achievement2} alt="" /> </div>

                <div className='bg-[#f5f3f2]  md:-mr-12 md:flex flex-col justify-center md:px-4 md:h-[90%] md:mt-5 flex flex-col justify-center py-5 px-2 h-full -mt-5 md:ml-8  shadow-lg'>

                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>২০২০</h2>
                    <h2 className='text-xl font-bold py-3 '>১০ম জেল হত্যা দিবস আন্তঃক্লাব বিতর্ক প্রতিযোগিতা, কবি জসিমউদ্দন হল ডিবেটিং ক্লাব, ঢাকা বিশ্ববিদ্যালয়।</h2>
                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>চ্যাম্পিয়ন</h2>
                    <p> <small> দেশের যে চার সূর্যসন্তানের নিষ্ঠুর হত্যাযজ্ঞের ঘটনায় হতবাক হয়েছিল সমগ্র বিশ্ব, তাদেরই স্মরণে কবি জসীমউদদীন হল ডিবেটিং ক্লাব আয়োজন করতে যাচ্ছে ১০ম জেলহত্যা দিবস আন্তঃক্লাব বারোয়ারী প্রতিযোগীতা ২০২০!১০ম জেলহত্যা দিবস আন্তঃক্লাব বারোয়ারী বিতর্ক প্রতিযোগিতা ২০২০
                        #প্রথম_স্থানঃ
                        মোঃ সাইফুল ইসলাম
                        এফ রহমান ডিবেটিং ক্লাব
                        ঢাকা বিশ্ববিদ্যালয়
                        ......</small></p>
                    <div>
                        <button onClick={() => window.open('https://www.facebook.com/events/900537467451072/?active_tab=discussion', '_blank', 'noopener,noreferrer')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className="articleCard my-32 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4  bg-right-top bg-no-repeat" style={{ backgroundImage: `url(${background1})` }}>
                <div> <img className='achievementPic' src={Achievement3} alt="" /> </div>

                <div className='bg-[#f5f3f2]   md:-ml-12 md:flex md:flex-col md:justify-center  md:px-4  md:mt-5 flex flex-col justify-center py-5 px-2 h-full md:h-[90%] -mt-5 shadow-lg  '>

                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>২০২০</h2>
                    <h2 className='text-xl font-bold py-3 '>মহান বিজয় দিবস রচনা প্রতিযোগিতা,ঢাকা ইউনিভার্সিটি ইয়ূথ অ্যাগেইন্সট হাঙ্গার।</h2>
                    <h2 className='text-2xl font-bold py-3 text-cyan-500 '>প্রথম স্থান</h2>
                    <p> <small> মহান বিজয় দিবস-২০২০ 🇧🇩 উপলক্ষে ঢাকা ইউনিভার্সিটি ইয়ুথ এগেইনস্ট হাঙ্গার(ডুয়াহ) আয়োজিত  "মুক্তিযুদ্ধের চেতনা ও আমাদের সংস্কৃতি ভাবনা" শীর্ষক প্রবন্ধ লেখা প্রতিযোগিতা-২০২০,স্পন্সর্ড বাই Four Six One  এর বিজয়ীদের নাম ঘোষণা করা হলো-
                        ১ম-  মোঃ সাইফুল ইসলাম খান
                        ইতিহাস বিভাগ, ২০১৫-১৬ সেশন, স্যার এ এফ রহমান হল।
                        ........</small></p>
                    <div>
                        <button onClick={() => window.open('https://www.facebook.com/duyah.org/posts/pfbid035Hi8j6Ec81zogEMw1ZG98TGxyi7bMopLybi5kkYAdbmbyg9SSJGgXzUbGXMTG3Mkl', '_blank', 'noopener,noreferrer')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className='text-center'>
                <button className=' px-3 py-2 mt-5 rounded-md bg-cyan-500' onClick={() => navigate('/building_page')} >More Articles</button>
            </div>










        </div>





































        //         <div>

        //             <AnimationOnScroll  animateIn="animate__tada">
        //         <h1 className="text-3xl font-bold my-32 " > Some Of <span style={{color:'rgb(11 194 194)'}}> My Achievements </span></h1>
        //         </AnimationOnScroll>



        //         <div className='grid grid-cols-1 gap-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 justify-items-center align-middle my-10'>
        //         <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        //            <div className="articleCard w-80 md:w-96 lg:w-96 2xl:w-96  p-4 bg-[#f5f3f2] rounded-xl" >
        //                <div> <img src={Achievement1} alt="" /> </div>
        //                <div>
        //                    <h2 className='text-2xl font-bold py-3'>আমার বঙ্গবন্ধু বক্তৃতা প্রতিযোগিতায় লক্ষ টাকা জয়ী</h2>
        //                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, necessitatibus fugiat! Possimus ullam iure quam error perspiciatis quaerat porro expedita quo, rem ipsam ab nam dolor corporis, blanditiis, modi voluptates. Velit, excepturi?</p>
        //                </div>
        //            </div>
        //            </AnimationOnScroll>
        //            <AnimationOnScroll animateIn="animate__fadeInDownBig">
        //            <div className="articleCard w-80 md:w-96 lg:w-96 2xl:w-96 p-4 bg-[#f5f3f2] rounded-xl">
        //            <div> <img src={Achievement1} alt="" /> </div>
        //                <div>
        //                    <h2 className='text-2xl font-bold py-3'>আমার বঙ্গবন্ধু বক্তৃতা প্রতিযোগিতায় লক্ষ টাকা জয়ী</h2>
        //                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, necessitatibus fugiat! Possimus ullam iure quam error perspiciatis quaerat porro expedita quo, rem ipsam ab nam dolor corporis, blanditiis, modi voluptates. Velit, excepturi?</p>
        //                </div>
        //            </div>
        //            </AnimationOnScroll>
        //            <AnimationOnScroll animateIn="animate__fadeInRightBig">
        //            <div className="articleCard w-80 md:w-96 lg:w-96 2xl:w-96 p-4 bg-[#f5f3f2] rounded-xl">
        //            <div> <img src={Achievement1} alt="" /> </div>
        //                <div>
        //                    <h2 className='text-2xl font-bold py-3'>আমার বঙ্গবন্ধু বক্তৃতা প্রতিযোগিতায় লক্ষ টাকা জয়ী</h2>
        //                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, necessitatibus fugiat! Possimus ullam iure quam error perspiciatis quaerat porro expedita quo, rem ipsam ab nam dolor corporis, blanditiis, modi voluptates. Velit, excepturi?</p>
        //                </div>
        //            </div>
        //            </AnimationOnScroll>
        //         </div>
        //       <div className='text-center'>
        //       <button className=' px-3 py-2 mt-5 rounded-md bg-cyan-500' onClick={() => navigate('/all-achivements')}>More Achievements</button>
        //       </div>
        //    </div>
    );
};

export default Achievements;