import React, { useState, useEffect } from 'react';
import './TourAndImage.css'
import 'tw-elements';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

import akbarAliKhan from '../../../Images/akbarAliKhan.jpg';
import photo1 from '../../../Images/photo1.jpg'
import photo2 from '../../../Images/photo2.jpg'
import photo3 from '../../../Images/photo3.jpg'
import photo4 from '../../../Images/photo4.jpg'
import photo5 from '../../../Images/photo5.jpg'
import photo6 from '../../../Images/photo6.jpg'
import photo7 from '../../../Images/photo7.jpg'
import photo8 from '../../../Images/photo8.jpg'



const TourAndImage = () => {

    return (
        <div className='mt-10 px-4'>
            <div className='col-span-0'>
                <p className=' text-2xl lg:pr-3 '>Photos</p>
            </div>

            {/* --------------------------Carosel Start----------------------- */}

            <div id="carouselExampleControls" class="carousel slide relative" data-bs-ride="carousel">
                <div class="carousel-inner relative w-full overflow-hidden">
                    <div class="carousel-item active relative float-left w-full">

                        <div>
                            <div className='   grid grid-cols-1 gap-3 md:grid-cols-3  lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 scroll imageGallary '>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={akbarAliKhan} alt="Sunset in the mountains" index="0" />
                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo1} alt="Sunset in the mountains" index="1" />
                                   

                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo2} alt="Sunset in the mountains" index="2" />
                                    

                                  

                                </div>



                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo3} alt="Sunset in the mountains" index="3" />
                                    

                                    
                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo4} alt="Sunset in the mountains" index="4" />
                                   

                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo5} alt="Sunset in the mountains" index="5" />
                                   

                                    

                                </div>






                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo6} alt="Sunset in the mountains" index="6" />
                                  

                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo7} alt="Sunset in the mountains" index="7" />
                                    

                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo8} alt="Sunset in the mountains" index="8" />
                                   

                                   

                                </div>




                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo6} alt="Sunset in the mountains" index="9" />
                                   

                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo7} alt="Sunset in the mountains" index="10" />
                                   

                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src={photo8} alt="Sunset in the mountains" index="11" />
                                   

                                   

                                </div>
                            </div>

                        </div>



                    </div>
                    <div class="carousel-item relative float-left w-full">
                      
                    <div>
                            <div className='   grid grid-cols-1 gap-3 md:grid-cols-3  lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 scroll imageGallary '>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t1.6435-9/91310467_1106249416392817_5016772576278478848_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=e3f864&_nc_eui2=AeErJmuDv3ZkedKt89-dTj8_oTv2hM3o0pOhO_aEzejSk3KVEj64vQxFd6_44wKYxOdceDi05mKL_g8JWtAAbHKO&_nc_ohc=1iVmbY8szN0AX9uieSI&_nc_ht=scontent.fdac148-1.fna&oh=00_AfA2p5oKe-e9GJ7RkCeeZ3LUNonT9VKJMAXvy8KEwCvzCA&oe=63959B7A' alt="Sunset in the mountains" index="0" />
                                   

                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t1.6435-9/43823119_726365794381183_153482567583006720_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=19026a&_nc_eui2=AeGoA_fKcvmg3ef730NsOnp-DeLytj0W1eYN4vK2PRbV5rQfY9_rr3KbS-T6ozTeUXCxEoixiLf5hcgGPegmMY5M&_nc_ohc=FiBjKMVPglMAX_hvxmi&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCP-Y-e56M7ez1cRgPPfIu89xBvQDxwBthWckSp3zYRyQ&oe=6395B351' alt="Sunset in the mountains" index="1" />
                                   

                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306442036_3115032938757645_1591726089996542916_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEIv8EDtf0K-bbyOEm60s1YLlQl_pPLwMcuVCX-k8vAxx5rr8d6077miam2_3grjCSrrXFg2ULl3pzb8MV7v7Zd&_nc_ohc=f5wXQKcrNGEAX-H3ZMd&_nc_ht=scontent.fdac148-1.fna&oh=00_AfC7Wrp9TUKXk40BEPlQjTCRVZDdWG7VeT_z57DZL0wQeQ&oe=63726DD2' alt="Sunset in the mountains" index="2" />
                                  

                                  

                                </div>



                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306819511_3115988278662111_6362292911883695185_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFmGFRAfvNK9OJHgpGlS2dGT9zRfPrZcOJP3NF8-tlw4uhbDNX4-izKqVUwuJgeiGsfedtjK9qlq-vwMb3WONBK&_nc_ohc=SwxTYaNB_k0AX9iGyx5&_nc_ht=scontent.fdac148-1.fna&oh=00_AfALNo8b0HLJPb0MvTn_ORuRhf0ZkXP6NOq1n3IlHWXYrg&oe=6372F197' alt="Sunset in the mountains" index="3" />
                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/308500864_3124000937860845_8391095256912853562_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFIC0fD-Yke0G4ue9lZPiXsXoITYoUJiYheghNihQmJiHzS6imGKuaA6E-g8SMVmxgtKyA6J92qpMV6gRnEgh28&_nc_ohc=VzzLsSAPIEYAX8x4jTk&tn=6lMtMRGEztsjpvwC&_nc_ht=scontent.fdac148-1.fna&oh=00_AfBRcfTwXygk1Musi9A50vq6AOjjkz72DP_r3IXG9Im8AA&oe=63740B4E' alt="Sunset in the mountains" index="4" />
                                    
                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306774120_3116601195267486_3159500269582368549_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEPYaYJqB1CB9_EB8MgBRxy2l7f1d8s_W_aXt_V3yz9b1l7FD7fbt-0g1bM9wHB93U6rEtIvMEqebKYDYjX6rov&_nc_ohc=2nWp9E0QMf4AX8Lvoa2&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCJoxuvKGXuF2bJgG4JTazXgJMUko-GSKMXTR9EIDugIA&oe=6374180A' alt="Sunset in the mountains" index="5" />
                                   

                                </div>






                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/308166774_3124449361149336_7944690620819402047_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEU9_HiGQ-EluSl2AzJpaxtMe3sJtMOzocx7ewm0w7Oh0fLSOEIParCAsMmpTehXu9DPndPkc1dKrLw8ESJYKBR&_nc_ohc=kRCmJ7dMWGMAX8wAvta&_nc_ht=scontent.fdac148-1.fna&oh=00_AfBygBUHKKOGRugSwKZr0O3_F_B6fR_-KKzzEbNk7YKRsA&oe=63735D17' alt="Sunset in the mountains" index="6" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/308921185_3127509024176703_8259962573514818656_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHXJNukNNareju-0EBAktkfG2XtstQxH0UbZe2y1DEfRdw3bnmpaoUaExzx7HJPLzITJY1hSpGZMZL9KsygFF9C&_nc_ohc=cD4I2YkbXmUAX8MFqxT&_nc_ht=scontent.fdac148-1.fna&oh=00_AfAtGkxI282gl3tp-cB0d9v0E52JuH0eKt9lUPtQWYYG1g&oe=63734B90' alt="Sunset in the mountains" index="7" />
                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/309503492_3130139930580279_6892853087236951535_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFD8K0DvgLX4hngw8CraCxR-L64Z6ckDKT4vrhnpyQMpFvZis7qKW6Sk0sRgdQObPk7VZszFlc9mm1EgH__t9lX&_nc_ohc=Z2h5N-d6Ca0AX8ZHolD&_nc_ht=scontent.fdac148-1.fna&oh=00_AfDNp_m8C4bA5q330t40v05u963pPoudWhun_sU6zAB8Pw&oe=63731F48' alt="Sunset in the mountains" index="8" />
                                    

                                </div>




                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/309416205_3130140087246930_4419478939069193871_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEIWN82ocOprIz3KKrMfB7hrKEAi-te08WsoQCL617TxWf0LP4nGo5DWfxcUDxi_mGNgeJyVA7qZCaSv6HlhIwR&_nc_ohc=4ztVnHaoihoAX-QmMuz&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCu0s56hqno5FOlIiLx7jedHDmzh19ddpls_UCRLn2PQQ&oe=6373AA0E' alt="Sunset in the mountains" index="9" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/309708717_3130140150580257_5421940889050809163_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeG5VPKNIIyex7LdP1evNg2ASeXL5diRMRRJ5cvl2JExFIzilMZsJICcz8JzARNps80A5MBxGSFs2Eu28a58bKul&_nc_ohc=VZu5_7lUr0QAX_aHJ0-&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCxqVIA0nVg0hqz-BUxxaT-07KixKG_XA0ITry2VfFXEA&oe=63723EB2' alt="Sunset in the mountains" index="10" />
                                    

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/310843319_3137864863141119_3524516268572662439_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFSH9CDeUKn0qohjRbNXR-T_7giwvhilS__uCLC-GKVL6h8992U3MzUqCIqdWQlJEhMMK6TM744NNLBMhXf5acW&_nc_ohc=SSgUPUaXJp8AX8ISjsk&_nc_ht=scontent.fdac148-1.fna&oh=00_AfAHFnp5WPSV1ItTLjXc6Ca9kAujvLkfCtIM92dWW8BWTw&oe=6372680D' alt="Sunset in the mountains" index="11" />
                                    

                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="carousel-item relative float-left w-full">
                        
                    <div>
                            <div className='   grid grid-cols-1 gap-3 md:grid-cols-3  lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 scroll imageGallary '>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/310765589_3137864973141108_159933962503123599_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHLwRJSZeW3taTxeAZjyenIun8pBBsEn7S6fykEGwSftGX26fYOzZdk750S35pKlR4T7wiBh1mmvvi5ZSY-g0SC&_nc_ohc=3XXgoSuWaSUAX-MkibS&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCPjumFGRUIzTmgjAOMxneefi0rohc5SvwV-5OxDT0FDg&oe=63736ED2' alt="Sunset in the mountains" index="0" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t31.18172-8/10687909_1410470965880526_171312148104600224_o.jpg?_nc_cat=102&ccb=1-7&_nc_sid=ba80b0&_nc_eui2=AeGpj-1IYiAisG5btz_yqpt-Ss85kPAVLWRKzzmQ8BUtZOSByFYj7yqctedA856xcwrrjqgbNDQ0v8CruSKCbp8d&_nc_ohc=aL-N1N9qFiYAX--jN0q&_nc_oc=AQl4DtVG8YTZxlLQWSPhw0vygJWhOb_Bx6Cx1aj91Yl-y9w1Sy3_BnBYvd4QTjNWKRw&_nc_ht=scontent.fdac148-1.fna&oh=00_AfBuuxcWvivG4WT6ICKyii2SIUmoDpWmEAvRMvxa-og7aw&oe=63959E17' alt="Sunset in the mountains" index="1" />
                                 

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306584648_3115032728757666_8259226103903103139_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeG7Ao16uLg9cokdtS7HJQrMmj81_vvk282aPzX---TbzR0SNAjeHHJesbSfeQ_YTW8SC0YJpN66phtVFT3NY1KU&_nc_ohc=oPcegzPVxUkAX_0z4-9&tn=6lMtMRGEztsjpvwC&_nc_ht=scontent.fdac148-1.fna&oh=00_AfAV-utMbPV1JwbSeOO65-Cy4o8zZ8O1aQOGctuPukiYew&oe=6372C975' alt="Sunset in the mountains" index="2" />
                                  

                                </div>



                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306547146_3115032668757672_265162016159868807_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHAYO3nAzPzM3EVCkeBQkao5U0c3t6aX0TlTRze3ppfRJUpOqu-ptdgLcWhXPgLsdyr2ZSJ7XcIQs8LeLyCRFFb&_nc_ohc=3pd-Fyygne0AX8lSZKx&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCbEat6rZfYNpiEon4XY_u00oWfCrSXWbk4XqQcwtgMug&oe=637419E8' alt="Sunset in the mountains" index="3" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306505129_3115032502091022_1667637601138412727_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEJ6KV3g7zX3Wgr13fVbckxR6aKv2cvyTxHpoq_Zy_JPHY8GyAfe-clGhIvzmTeishzeeiy4k_fh5RlDR3D3Wrf&_nc_ohc=FvLTEAz2LSMAX_aJpWe&_nc_ht=scontent.fdac148-1.fna&oh=00_AfANB99e6xv2kmNWUgna8T5hlyiB1fHyx3K8HYsxCAH0Wg&oe=63738323' alt="Sunset in the mountains" index="4" />
                                   

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306589776_3115032462091026_8455190162253290717_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGafYtGlAvq-9LFaCsiw6AvR1aYmlk6b1VHVpiaWTpvVZmqfQ4Xz6xlj5Ga9pwHrkjaSbrh0TXOvt1dHkXOeZ7H&_nc_ohc=yR_mH1bcqVEAX_56d8t&_nc_ht=scontent.fdac148-1.fna&oh=00_AfBOqiYOIBLv2DVmL592Wy6k8TR_F2pjaKi5lqH2trVlGQ&oe=63738382' alt="Sunset in the mountains" index="5" />
                                    

                                </div>






                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306581070_3115031762091096_8168813762758402098_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeE-hn6FlwW-mph36VtPd1Iy_5yUm7udnj3_nJSbu52ePb1Uk-oy84os11GoSdlkHE2hY2HbstngAfCG8YUUT28g&_nc_ohc=Uk_LZpJt16kAX-c_X1R&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCrCFFwp_-mMg3BmIMW6N7nMm7k2NrggMTZ5X7SdTPYhA&oe=63736EA2' alt="Sunset in the mountains" index="6" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306335916_3114381115489494_438339523693468557_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeE-XD_djIKgEYuzgtaq44zXeodU2E1Lf4d6h1TYTUt_hzcoNa-k78Uy9J2oPO11oWFR_fh-KPFd-0tJ0QGlFFF1&_nc_ohc=kZRtMfJNdXQAX93M3d6&_nc_ht=scontent.fdac148-1.fna&oh=00_AfCjtwIUAerCYv0TK1JXo_SNZi5QaGG7vowJOw8K5jwqiA&oe=6373F593' alt="Sunset in the mountains" index="7" />
                                

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306327225_3114380535489552_4024255660697462962_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGLD5ia_zBvPe8NV-jP6e6gFGJahkxqR04UYlqGTGpHTkPp2iwvAPUQn41GjofLuZaj3a3o7M97D89yWC9hLFUY&_nc_ohc=AQvj-NHHVdUAX95i14w&_nc_ht=scontent.fdac148-1.fna&oh=00_AfARl3KUpf6SXavDY-8ZdvUqETiD0U3IEjHyHNeZDtFg0Q&oe=63733DB9' alt="Sunset in the mountains" index="8" />
                                    

                                </div>




                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306477523_3114380315489574_2652329093635034646_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEGmOBdHADaAilwGOmC9zPXrs35MJ09HeWuzfkwnT0d5aBWcGkzPmZyd7bdM1_xi8wL2m0tttU7PblCGYzUB-_s&_nc_ohc=gOw_1h9Ep4YAX-cUcMP&_nc_ht=scontent.fdac148-1.fna&oh=00_AfAjEl4bRIZUa02DmS68LG7R_cejRa_u2mwVLPsIH_oUaA&oe=6373CBFD' alt="Sunset in the mountains" index="9" />
                                    <div class="px-6 py-4">

                                    </div>

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306321074_3114380205489585_1323091556024636678_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEVw8dmXqXhuc-4C0VfwDP85BAhPqj0VD7kECE-qPRUPgbsIm3qMVIo9XidtqsRnboVLZVcdNDKj4zAqoMK8QQ5&_nc_ohc=jxsvqLhEklYAX__w788&_nc_ht=scontent.fdac148-1.fna&oh=00_AfBbu4mW2QhOgasaalqHNTbJI35rhnoS9jqk0ErRxB1i-w&oe=63741867' alt="Sunset in the mountains" index="10" />
                                 

                                </div>
                                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                                    <img src='https://scontent.fdac148-1.fna.fbcdn.net/v/t39.30808-6/306193716_3114049242189348_5234699659216444830_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFBrl-WcUo4T6YYpjkhu4PNKeXTXqXuXdkp5dNepe5d2VSsFHagal4yKehQHtmSr3ckcUOavojmBp3cJ3VM0LoQ&_nc_ohc=dsuRvHZPEJYAX_JT3-h&_nc_ht=scontent.fdac148-1.fna&oh=00_AfDpEdIqsUtXD-DvH0RZIVab1gmK8vV1fxiBMHqBqd90JQ&oe=63740634' alt="Sunset in the mountains" index="11" />
                                   

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button
                    class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                >
                    <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                    class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                >
                    <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
















































{/* 
                <div>
                    <div className='   grid grid-cols-1 gap-3 md:grid-cols-3  lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 scroll imageGallary '>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={akbarAliKhan} alt="Sunset in the mountains" index="0" />
                            <div class="px-6 py-4">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo1} alt="Sunset in the mountains" index="1" />
                            <div class="px-2 py-2">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo2} alt="Sunset in the mountains" index="2" />
                            <div class="px-6 py-4">

                            </div>

                        </div>



                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo3} alt="Sunset in the mountains" index="3" />
                            <div class="px-6 py-4">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo4} alt="Sunset in the mountains" index="4" />
                            <div class="px-2 py-2">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo5} alt="Sunset in the mountains" index="5" />
                            <div class="px-6 py-4">

                            </div>

                        </div>






                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo6} alt="Sunset in the mountains" index="6" />
                            <div class="px-6 py-4">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo7} alt="Sunset in the mountains" index="7" />
                            <div class="px-2 py-2">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo8} alt="Sunset in the mountains" index="8" />
                            <div class="px-6 py-4">

                            </div>

                        </div>




                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo6} alt="Sunset in the mountains" index="9" />
                            <div class="px-6 py-4">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo7} alt="Sunset in the mountains" index="10" />
                            <div class="px-2 py-2">

                            </div>

                        </div>
                        <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                            <img src={photo8} alt="Sunset in the mountains" index="11" />
                            <div class="px-6 py-4">

                            </div>

                        </div>
                    </div>

                </div> */}
                








































            {/* <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={akbarAliKhan} alt="Sunset in the mountains" index="0" />
                    <div class="px-6 py-4">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo1} alt="Sunset in the mountains" index="1" />
                    <div class="px-2 py-2">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo2} alt="Sunset in the mountains" index="2" />
                    <div class="px-6 py-4">

                    </div>

                </div>



                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo3} alt="Sunset in the mountains" index="3" />
                    <div class="px-6 py-4">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo4} alt="Sunset in the mountains" index="4" />
                    <div class="px-2 py-2">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo5} alt="Sunset in the mountains" index="5" />
                    <div class="px-6 py-4">

                    </div>

                </div>






                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo6} alt="Sunset in the mountains" index="6" />
                    <div class="px-6 py-4">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo7} alt="Sunset in the mountains" index="7" />
                    <div class="px-2 py-2">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo8} alt="Sunset in the mountains" index="8" />
                    <div class="px-6 py-4">

                    </div>

                </div>




                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo6} alt="Sunset in the mountains" index="9" />
                    <div class="px-6 py-4">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo7} alt="Sunset in the mountains" index="10" />
                    <div class="px-2 py-2">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo8} alt="Sunset in the mountains" index="11" />
                    <div class="px-6 py-4">

                    </div>

                </div>




                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo6} alt="Sunset in the mountains" index="12" />
                    <div class="px-6 py-4">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo7} alt="Sunset in the mountains" index="13" />
                    <div class="px-2 py-2">

                    </div>

                </div>
                <div class="max-w-sm rounded singleImage overflow-hidden shadow-lg">
                    <img src={photo8} alt="Sunset in the mountains" index="14" />
                    <div class="px-6 py-4">

                    </div>
                </div> */}

            {/* </Carousel> */}
        </div>
    );
};

export default TourAndImage;