import React from 'react';
import AllAchievements from '../Home/Achievements/AllAchievements';

const AboutMe = () => {
    return (

        <div className='' >
            <AllAchievements></AllAchievements>
        </div>
    );
};

export default AboutMe;