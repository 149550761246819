import React from 'react';
import { useNavigate } from 'react-router-dom';
import article2 from '../../../Images/Bangabandhu.png';

const Articles = () => {
    const navigate = useNavigate()
    const handleReadMore = () => {
        navigate('/full_article')
    }
    return (
        <div>
            <h1 className="text-3xl font-bold my-32 "> Some Of <span style={{ color: 'rgb(11 194 194)' }}> My Articles </span></h1>

            {/* <div className='grid grid-cols-3 gap-3 justify-items-center align-middle my-10'> */}
            <div className="articleCard grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4  " >
                <div> <img className='rounded-xl' src={article2} alt="" /> </div>
                <div className='bg-[#f5f3f2] rounded-xl  md:-ml-12 md:flex flex-col justify-center md:px-4 md:h-72 md:mt-5 flex flex-col justify-center py-5 px-2 h-80 -mt-5 '>
                    <h2 className='text-2xl font-bold py-3 '>বঙ্গবন্ধুর মননে পিছিয়ে পড়া মানুষ</h2>
                    <p>সেবার ভালো ফসল হয়নি। গরিব কৃষকদের কপালে দুশ্চিন্তার ভাজ, ঘরে স্ত্রী-সন্তানদের হাহাকার। এমনি কিছু অসহায় কৃষককে বাড়িতে ডেকে এনে বাবার অনুমতি ছাড়াই গোলা কেটে ধান বিলিয়ে দিয়েছিল এক খোকা। ছেলের এই কাণ্ড শুনে.........</p>
                    <div>
                        <button onClick={() => window.open('https://banglarunner.com/post.php?post_id=863', '_blank', 'noopener,noreferrer')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className="articleCard grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4  " >
                <div> <img className='rounded-xl' src='https://www.bd-journal.com/assets/news_photos/2017/09/01/image-5408-1504256858.jpg' alt="" /> </div>
                <div className='bg-[#f5f3f2] rounded-xl  md:-ml-12 md:flex flex-col justify-center md:px-4 md:h-72 md:mt-5 flex flex-col justify-center py-5 px-2 h-80 -mt-5 '>
                    <h2 className='text-2xl font-bold py-3 '>বৃদ্ধা মাকে পিঠে নিয়ে সীমান্ত পেরিয়ে আসা রোহিঙ্গা যুবকের গল্প</h2>
                    <p>বুধবার দুপুর, আকাশে তখন আগুন বৃষ্টি ঝড়ানো সূর্য। মিয়ানমারের রাখাইন রাজ্যে দেশটির সেনাবাহিনীর অত্যাচার এবং গণহত্যার ফলে জীবন ভয়ে পালিয়ে বেড়ানো একদল গৃহহারা রোহিঙ্গা,....</p>
                    <div>
                        <button onClick={() => window.open('https://www.bd-journal.com/bangladesh/5408/%E0%A6%AC%E0%A7%83%E0%A6%A6%E0%A7%8D%E0%A6%A7%E0%A6%BE-%E0%A6%AE%E0%A6%BE%E0%A6%95%E0%A7%87-%E0%A6%AA%E0%A6%BF%E0%A6%A0%E0%A7%87-%E0%A6%A8%E0%A6%BF%E0%A7%9F%E0%A7%87-%E0%A6%B8%E0%A7%80%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A4-%E0%A6%AA%E0%A7%87%E0%A6%B0%E0%A6%BF%E0%A7%9F%E0%A7%87-%E0%A6%86%E0%A6%B8%E0%A6%BE-%E0%A6%B0%E0%A7%8B%E0%A6%B9%E0%A6%BF%E0%A6%99%E0%A7%8D%E0%A6%97%E0%A6%BE-%E0%A6%AF%E0%A7%81%E0%A6%AC%E0%A6%95%E0%A7%87%E0%A6%B0-%E0%A6%97%E0%A6%B2%E0%A7%8D%E0%A6%AA?fbclid=IwAR2XrWCLt_BdB8muW3W8EmzV4SqTeAOgTkirRBmUwcQXPXISDKHvj76geMU', '_blank')} className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className="articleCard grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 p-4  " >
                <div> <img className='rounded-xl' src='https://images.prothomalo.com/prothomalo-bangla%2F2022-03%2F57f84af8-68a8-46c1-921f-48e900543550%2FBondhushava.jpg?auto=format%2Ccompress&format=webp&w=640&dpr=1.0' alt="" /> </div>
                <div className='bg-[#f5f3f2] rounded-xl  md:-ml-12 md:flex flex-col justify-center md:px-4 md:h-72 md:mt-5 flex flex-col justify-center py-5 px-2 h-80 -mt-5 '>
                    <h2 className='text-2xl font-bold py-3 '>বন্ধুসভায় নতুন নেতৃত্ব</h2>
                    <p>প্রথম আলো বন্ধুসভা জাতীয় পরিচালনা পর্ষদের নতুন কমিটি গঠন করা হয়েছে। এই কমিটিতে সভাপতির দায়িত্ব পালন করবেন বিতার্কিক উত্তম রায়। নির্বাহী সভাপতির পদে এসেছেন উপস্থাপক মৌসুমী মৌ। সাধারণ সম্পাদক নির্বাচিত হয়েছেন সংবাদ উপস্থাপক জাফর সাদিক।....</p>
                    <div>
                        <button onClick={() => window.open('https://www.prothomalo.com/bangladesh/%E0%A6%AC%E0%A6%A8%E0%A7%8D%E0%A6%A7%E0%A7%81%E0%A6%B8%E0%A6%AD%E0%A6%BE%E0%A7%9F-%E0%A6%A8%E0%A6%A4%E0%A7%81%E0%A6%A8-%E0%A6%A8%E0%A7%87%E0%A6%A4%E0%A7%83%E0%A6%A4%E0%A7%8D%E0%A6%AC-2?fbclid=IwAR1EslhPR9QkgFV-48_5V3PQRsRTUvKajVNa2sj2ynHDuHiIhErOHThiDx4','_blank') } className=' px-2 py-1 mt-2 rounded-md bg-cyan-500 md:mt-5 md:px-3 md:py-2'>  Read More</button>
                    </div>

                </div>
            </div>
            <div className='text-center'>
                <button className=' px-3 py-2 mt-5 rounded-md bg-cyan-500' onClick={() => navigate('/building_page')} >More Articles</button>
            </div>
        </div>
    );
};

export default Articles;