/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';

import { faFacebook, faGithub, faLinkedin, faTwitter, faYoutube,faInstagram } from '@fortawesome/free-brands-svg-icons';
import LogoPic from '../../../Images/AboutMe.jpg'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'About Me', href: 'https://msi-khan.netlify.app/all-achivements', current: false },
  { name: 'Achievements', href: 'https://msi-khan.netlify.app/all-achivements', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">

                  <img src={LogoPic} className='h-10 w-10 rounded-full' alt="" />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                      href={item.href}
                        key={item.name}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}


                <div className="socialMedia list-none grid grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-2">
                  <li ><FontAwesomeIcon icon={faFacebook} size='x' className='cursor-pointer text-white' onClick={() => window.open("https://www.facebook.com/msikhan11", '_blank', 'noopener,noreferrer')} /></li>
                  <li><FontAwesomeIcon icon={faTwitter} size='x' className='cursor-pointer text-white' onClick={() => window.open("https://twitter.com/msikhan11", '_blank', 'noopener,noreferrer')} /></li>
                  <li><FontAwesomeIcon icon={faLinkedin} size='x' className='cursor-pointer text-white' onClick={() => window.open()} /></li>
                  <li><FontAwesomeIcon icon={faInstagram} size='x' className='cursor-pointer text-white' onClick={() => window.open("https://www.instagram.com/msi.khan", '_blank', 'noopener,noreferrer')} /></li>
                  <li><FontAwesomeIcon icon={faYoutube} size='x' className='cursor-pointer text-white' onClick={() => window.open("https://www.youtube.com/msikhan", '_blank', 'noopener,noreferrer')} /></li>
                </div>

                {/* Profile dropdown */}

              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                href={item.href}
                  key={item.name}
                  as="a"
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
