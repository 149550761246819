import React from 'react';
import tarkajaal from '../../../Images/tarkajaaljpg.jpg';
import banglaRunner from '../../../Images/banglaRunnerlogo.png';
import boiPottor from '../../../Images/BoiPottor.jpg';
import './founder.css'

const Founder = () => {
    return (
        <div className='bg-[#f5f3f2]'>
            <p className=' text-2xl text-center pt-8 '>Founder</p>
        <div className=' grid grid-cols-1 md:grid-cols-1  lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 place-items-center place-content-center'>

            <div className='flex flex-col justify-center items-center p-8 hover:cursor-pointer'>
                <img src={tarkajaal} className='foundingLogo rounded-full  '  alt="" />
                <p>TarkaJaal</p>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, odio. Sunt, numquam.</p>
            </div>
            <div  className='flex flex-col justify-center items-center p-8 hover:cursor-pointer'>
            <img src={banglaRunner} className='foundingLogo' alt="" />
                <p>Bangla Runner</p>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, odio. Sunt, numquam.</p>
            </div>
            <div  className='flex flex-col justify-center items-center p-8 hover:cursor-pointer'>
            <img src={boiPottor} className='foundingLogo rounded-full' alt="" />
                <p>Boi Pottor</p>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, odio. Sunt, numquam.</p>
            </div>
        </div>
        </div>
    );
};

export default Founder;