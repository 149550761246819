import React from 'react';
import Achievements from '../Achievements/Achievements';
import Articles from '../Articles/Articles';
import Founder from '../Founder/Founder';
import GetInTouch from '../GetInTouch/GetInTouch';
import HeroSrction from '../HeroSection/HeroSrction';
import Navbar from '../Navbar/Navbar';
import News from '../News/News';
import OnTelevision from '../OnTelevision/OnTelevision';
import OrganizationalActivities from '../OrganizationalActivities/OrganizationalActivities';
import Speech from '../Speech/Speech';
import TourAndImage from '../TourAndImage/TourAndImage';
import Videos from '../Videos/Videos';

const Home = () => {
    return (
        <div >
            <Navbar></Navbar>
            <div className=' max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
                <HeroSrction></HeroSrction>
                <Achievements></Achievements>
                <Speech></Speech>
                <Articles></Articles>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl::grid-col-2'>
                <OrganizationalActivities></OrganizationalActivities>
                <TourAndImage></TourAndImage>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl::grid-col-2'>
                <News></News>
                <OnTelevision></OnTelevision>
                </div>
                <Founder></Founder>
                <GetInTouch></GetInTouch>
            </div>
        </div>
    );
};

export default Home;